@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0;
  min-height: calc(100vh - 68px);
  /* background: rgb(14, 16, 16);
  background: radial-gradient(circle, rgba(14, 16, 16, 1) 0%, rgba(39, 39, 46, 1) 95%, rgba(52, 52, 61, 1) 100%); */
  background: linear-gradient(rgba(8, 18, 52) 0%, rgba(2, 11, 36) 100%);
}

.text-gradient {
  /* background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; */
  color: rgba(0, 174, 239);
}

@media (max-width: 899px) {
  .hero {
    min-height: calc(100vh - 48px);
  }

  .hero::before {
    height: 48px;
  }
}

section {
  display: flex;
  justify-content: center;
  padding: 150px 0;
}

@media (max-width: 899px) {
  section {
    padding: 60px 0;
  }
}

@media (max-width: 600px) {
  section {
    padding: 87px 0;
  }
}

.features {
  background-color: rgba(20, 29, 56);
}

.cta {
  background-color: rgba(30, 43, 91);
  /* padding: 87px 0; */
}

.partners {
  background: rgba(2, 11, 36) url('assets/bg_partners.png') no-repeat top center;
  background-position-y: 100px;
}

.faq {
  background-color: rgba(20, 29, 56);
}

.evenSection {
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.fadeIn {
  scale: 0;
  opacity: 0;
  animation: fade-in linear forwards;
  animation-timeline: view();
  animation-range: entry;
}

@keyframes fade-in {
  to {
    scale: 1;
    opacity: 1;
  }
}

.spinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 8px;
  height: 40px;
  border-radius: 4px;
  display: block;
  margin: 20px auto;
  position: relative;
  background: currentColor;
  color: #90caf9;
  box-sizing: border-box;
  animation: animloader 0.3s 0.3s linear infinite alternate;
}

.spinner::after,
.spinner::before {
  content: '';
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: animloader 0.3s 0.45s linear infinite alternate;
}
.spinner::before {
  left: -20px;
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    height: 48px;
  }
  100% {
    height: 4px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
  margin-bottom: 20px;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 224px;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -12px;
  color: #030005;
  text-transform: uppercase;
  text-shadow: -1px -1px 0px #8400ff, 1px 1px 0px #ff005a;
  letter-spacing: -20px;
}

.notfound .notfound-404 h2 {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  left: 0;
  right: 0;
  top: 110px;
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 2px 0px #8400ff;
  letter-spacing: 12px;
  margin: 0;
}

@media (max-width: 599px) {
  .notfound .notfound-404 h1 {
    font-size: 182px;
  }

  .notfound .notfound-404 h2 {
    font-size: 24px;
  }
}

@keyframes animation-landing {
  0% {
    opacity: 0;
    transform: translate3d(0px, 50px, 0px);
  }

  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}

.blink {
  animation: blink 4s linear 0s infinite normal none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
